const state = {
    invoices: [],
    totalInvoices: null
  }
  
  const getters = {
    invoices: state => state.invoices,
    totalInvoices: state => state.totalInvoices
  }
  
  const mutations = {
    ADD_INVOICES: (state, invoices) => {
      state.invoices = invoices
    },
    TOTAL: (state, amount) => {
        state.totalInvoices = amount
      },
  }
  
  const actions = {
    getInvoices({ commit }, invoices) {
      commit("ADD_INVOICES", invoices);
    },
    addInvoices({commit}, invoices) {
        commit("ADD_INVOICES", invoices);
    },
    totalInvoices({commit}, invoices) {
        commit("TOTAL", invoices);
    }
  }
  
  export default {
    state,
    getters,
    mutations,
    actions
  }