import Vue from "vue"
import App from "./App.vue"
import router from "@/service/router"
import { store } from "./store"
import vuetify from "./plugins/vuetify"
import VueCookies from 'vue-cookies'
import locale from "@eencloud/core-components/src/service/locale"
import CoreComponents from "@eencloud/core-components"
import eewcComponents from "@eencloud/eewc-components/src";

import '@eencloud/core-components/src/plugins/sentry'
import Api from './service/api'
import Vuelidate from 'vuelidate'
import VueGtm from 'vue-gtm'
Vue.use(eewcComponents);

Vue.use(CoreComponents);
Vue.use(VueCookies);
Vue.use(Vuelidate)


Vue.config.productionTip = false;
/* eslint-disable no-undef */
Api.createAxiosInstance(config.apiServer);
Vue.prototype.restapi = Api
Vue.restapi = Vue.prototype.restapi

if (config['google-tag-manager']['enabled']) {
  Vue.use(VueGtm, {
    id: config['google-tag-manager']['tracking-id'],
    queryParams: {
      gtm_auth: config['google-tag-manager']['gtm_auth'],
      gtm_preview: config['google-tag-manager']['gtm_preview'],
      gtm_cookies_win:'x'
    },
    defer: false,
    compatibility: false,
    enabled: config['google-tag-manager']['enabled'],
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  });
}


router.afterEach(() => {
   document.title = config.title
})

export function changeFavicon (link) {
  const favicon = document.getElementById('favicon')
  if (favicon !== null) {
    favicon.href = '/' + link
  }
}

const i18n = locale.i18n;

const v = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
});

router.onReady(() => {
  v.$mount('#app');
});