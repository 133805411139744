const   BILLING_VIEW_INVOICE = 'i',
        BILLING_PAYMENT = '$',
        BILLING_ORDER = 'o',
        BILLING_VIEW_SHIPMENT = '>'


function hasInvoice(string) {
    return string.indexOf(BILLING_VIEW_INVOICE) !== -1
}
function hasPayment(string) {
    return string.indexOf(BILLING_PAYMENT) !== -1
}
function hasOrder(string) {
    return string.indexOf(BILLING_ORDER) !== -1
}
function hasShipment(string) {
    return string.indexOf(BILLING_VIEW_SHIPMENT) !== -1
}

export {
    hasInvoice,
    hasPayment,
    hasOrder,
    hasShipment
}