import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

const state = {
  loggedIn: null,
}

const getters = {
  loggedIn: state => state.loggedIn
}

const mutations = {
  LOG_OUT: (state) => {
    state.loggedIn = false
  },
  LOG_IN: (state) => {
    state.loggedIn = true
  },
}

const actions = {
  logout({ commit }) {
    commit("LOG_OUT");
  },
  login({ commit }) {
    commit("LOG_IN");
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
