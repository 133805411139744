import axios from "axios";
import CustomError from "../ErrorHandeling/CustomError";
import { API_CODES } from "../ErrorHandeling/ApiCodes";

class Api {
  constructor(
    API_KEY = "9518080e-7ab2-11ea-9b9b-b651b3817d40", // actual key is not (yet) checked on prod and test, but required for the billing API
  ) {
    this.API_KEY = API_KEY;
    /* eslint-disable no-undef */
    this.billingBaseURL = config.apiServer;
  }

  createAxiosInstance(baseURL) {
    this.instance = axios.create({
      withCredentials: true,
      baseURL: baseURL
    })
  }

  async getBillingAccountMetaData() {
    try {
      const res = await this.instance.get("/g/api/v2/billing/account", 
        { headers: { Authorization: "een-api " + this.API_KEY }, 
          withCredentials: true, 
          baseURL: this.billingBaseURL 
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw new CustomError(
        error.response ? error.response.data.status_code : "Unknown",
        API_CODES.GET_BILLING_ACCOUNT,
        error.response ? error.response.data.message : error
      );
    }
  }

  async getVMSAccountMetaData(accountId) {
    try {
      const res = await this.instance.get(`/g/account?id=${accountId}`);
      return res;
    } catch (error) {
      console.log(error);
      throw new CustomError(
        error.response ? error.response.data.status_code : "Unknown",
        API_CODES.GET_BILLING_META_DATA,
        error.response ? error.response.data.message : error
      );
    }
  }

  async getVMSUserData() {
    try {
      const res = await this.instance.get(`/g/user`);
      return res;
    } catch (error) {
      console.log(error);
      throw new CustomError(
        error.response ? error.response.data.status_code : "Unknown",
        API_CODES.GET_VMS_USER_DATA,
        error.response ? error.response.data.message : error
      );
    }
  }

  async getListOfInvoices(result_offset = "0", result_limit = "20", order, invoice_date) {
    try {
      const res = await axios.get(
        "/g/api/v2/billing/invoice/list?" +
          `result_limit=${result_limit}` +
          `&result_offset=${result_offset}` +
          `&order=${order}` +
          (invoice_date ? `&invoice_date=${invoice_date}` : ""),
          { headers: { Authorization: "een-api " + this.API_KEY }, withCredentials: true, baseURL: this.billingBaseURL }
      
      );
      return res.data;
    } catch (error) {
      console.log(error);
      throw new CustomError(
        error.response ? error.response.data.status_code : "Unknown",
        API_CODES.GET_INVOICES_LIST,
        error.response ? error.response.data.message : error
      );
    }
  }

  async fetchPDF(invoiceId) {
    try {
      const init = {
        method: 'GET',
        credentials: 'include',
        headers: { Authorization: "een-api " + this.API_KEY }
      };
      const res = await fetch(`${this.billingBaseURL}/g/api/v2/billing/invoice/${invoiceId}/pdf`, init);
      return res.blob(res);
    } catch (error) { 
      if (error.res) {
        console.log(error.res.data.message);
      } else {
        console.log(error.message);
      }
    }
  }

  async getInvoice(invoiceId) {
    try {
      const res = await axios.get(`/g/api/v2/billing/invoice/${invoiceId}`, 
      { headers: { Authorization: "een-api " + this.API_KEY }, withCredentials: true, baseURL: this.billingBaseURL });
      return res.data;
    } catch (error) {
      console.log(error);
      throw new CustomError(
        error.response ? error.response.data.status_code : "Unknown",
        API_CODES.GET_INVOICE,
        error.response ? error.response.data.message : error
      );
    }
  }

  async paymentInformation() {
    try {
      const res = await axios.get(
        `/g/api/v2/billing/paymentinformation`, 
        { headers: { Authorization: "een-api " + this.API_KEY }, withCredentials: true, baseURL: this.billingBaseURL });
      return res.data;
    } catch (error) {
      console.log(error);
      throw new CustomError(
        error.response ? error.response.data.status_code : "Unknown",
        API_CODES.GET_PAYMENT_INFO,
        error.response ? error.response.data.message : error
      );
    }
  }

  async getSummaryCSV(invoiceId) {
    try {
      const init = {
        method: 'GET',
        credentials: 'include',
        headers: { Authorization: "een-api " + this.API_KEY }
      };
      const res = await fetch(`${this.billingBaseURL}/g/api/v2/billing/invoice/${invoiceId}/summarycsv`, init);
      return res.blob(res);
    } catch (error) { 
      if (error.res) {
        console.log(error.res.data.message);
      } else {
        console.log(error.message);
        throw new CustomError(
          error.response ? error.response.data.status_code : "Unknown",
          API_CODES.GET_CSV_SUMMARY,
          error.response ? error.response.data.message : error
        );
      }
    }
  }

  async getDetailCSV(invoiceId) {
    try {
      const init = {
        method: 'GET',
        credentials: 'include',
        headers: { Authorization: "een-api " + this.API_KEY }
      };
      const res = await fetch(`${this.billingBaseURL}/g/api/v2/billing/invoice/${invoiceId}/detailcsv`, init);
      return res.blob(res);
    } catch (error) { 
      if (error.res) {
        console.log(error.res.data.message);
      } else {
        console.log(error.message);
        throw new CustomError(
          error.response ? error.response.data.status_code : "Unknown",
          API_CODES.GET_CSV_DETAIL,
          error.response ? error.response.data.message : error
        );
      }
    }
  }

  async initializePaymentMethod() {
    try {
      const res = await axios.post(
        `/g/api/v2/billing/initializepaymentmethod`,
        {},
        { headers: { Authorization: "een-api " + this.API_KEY }, withCredentials: true, baseURL: this.billingBaseURL }
    
      );
      return res;
    } catch (error) {
      console.log(error);
      throw new CustomError(
        error.response ? error.response.data.status_code : "Unknown",
        API_CODES.INITIALIZAE_PAYEMENT,
        error.response ? error.response.data.message : error
      );
    }
  }

  async editPaymentMethods(paymentID, data) {
    try {
      const res = await axios.put(
        `/g/api/v2/billing/paymentmethod/${paymentID}/
        `,
        data,
        { headers: { Authorization: "een-api " + this.API_KEY }, withCredentials: true, baseURL: this.billingBaseURL }
    
      );
      return res;
    } catch (error) {
      console.log(error.response);
      throw new CustomError(
        error.response ? error.response.data.status_code : "Unknown",
        API_CODES.EDIT_PAYMENT,
        error.response ? error.response.data.message : error
      );
    }
  }

  async deleteCard(cardID) {
    try {
      const response = await axios.delete(`/g/api/v2/billing/paymentmethod/${cardID}`, 
      { headers: { Authorization: "een-api " + this.API_KEY }, withCredentials: true, baseURL: this.billingBaseURL });
      return response;
    } catch (error) {
      throw new CustomError(
        error.response ? error.response.data.status_code : "Unknown",
        API_CODES.DELETE_CARD,
        error.response ? error.response.data.message : error
      );
    }
  }

  async getPaymentData() {
    try {
      const res = await axios.get(`/g/api/v2/billing/paymentmethod/list`, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "een-api " + this.API_KEY 
        },
        withCredentials: true, 
        baseURL: this.billingBaseURL
      });
      return res;
    } catch (error) {
      console.log(error);
      throw new CustomError(
        error.response ? error.response.data.status_code : "Unknown",
        API_CODES.GET_PAYMENT_DATA,
        error.response ? error.response.data.message : error
      );
    }
  }
}

export default new Api();