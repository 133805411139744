export const API_CODES = {
    EDIT_PAYMENT: 1,
    GET_PAYMENT_INFO: 2,
    GET_BILLING_ACCOUNT : 3,
    GET_BILLING_META_DATA : 4,
    GET_VMS_USER_DATA : 5,
    GET_INVOICES_LIST : 6,
    GET_PDF : 7,
    GET_INVOICE : 8,
    GET_CSV_SUMMARY : 9,
    GET_CSV_DETAIL : 10,
    INITIALIZAE_PAYEMENT : 11,
    DELETE_CARD : 12,
    GET_PAYMENT_DATA : 13
  }
  