<template>
  <v-app v-show="loggedIn !== null" class="app-background">
    <een-header v-if="loggedIn" :isAuthorized="true" :logo="logo" @logoClick="$router.push('/').catch(() => {})">
      <template v-slot:header v-if="$vuetify.breakpoint.smAndUp">
        <h1 class="white--text header-text">{{ $t("Business Portal") }}</h1>
      </template>
      <template v-slot:account-menu>
        <div class="pt-3 pr-2">
          <een-account-menu
            :langSwitcher="true"
            :showUserIcon="true"
            :externalLink="externalLink"
            :additionalData="hasPermissions ? additionalData : false"
            :name="name"
            :email="email"
            @logout="logout"
          />
        </div>
      </template>
    </een-header>
    <v-main>
      <v-container fluid fill-height class="py-0">
        <div v-if="loggedIn && !hasPermissions" class="d-flex align-center ma-auto">
          <div>
            {{
              $t(
                "There is no billing information available for your account or you don’t have permission. Please contact your partner for invoicing questions."
              )
            }}
          </div>
        </div>
        <router-view v-else v-show="!(!loggedIn && $route.name !== 'Login')" class="show-nav"></router-view>
      </v-container>
    </v-main>
    <een-footer
      v-if="isEEN"
      class="footer-billing"
      :privacyPolicyLink="privacyPolicy"
      :terms="false"
      :cookies="false"
    ></een-footer>
    <een-snack-bar
      :showing="$store.state.coreData.snackBar.showing"
      :timeout="$store.state.coreData.snackBar.timeout"
      :color="$store.state.coreData.snackBar.color"
      :text="$store.state.coreData.snackBar.text"
      :confirmation="$store.state.coreData.snackBar.confirmation"
      @closeSnackBar="$store.state.coreData.snackBar.showing = false"
    ></een-snack-bar>
  </v-app>
</template>
<script setup>
import { computed, onMounted, watch, ref } from "vue";
import { store } from "./store";
import restapi from "@/service/api";
import Theme from "@eencloud/core-components/src/service/themes";
import { getImgUrl } from "@eencloud/core-components/src/service/main";
import { logOut } from "@eencloud/eewc-components/src/service/auth.js";
import { getConfiguration } from "@eencloud/core-components/src/service/main";
import router from "@/service/router"

const logo = ref("");
const externalLink = ref({ url: "", name: "" });
const additionalData = ref({ title: "Account name", value: "" });

// eslint-disable-next-line no-unused-vars
const privacyPolicy = ref("https://www.een.com/privacy-policy/");

const loggedIn = computed(() => store.getters.loggedIn);
// eslint-disable-next-line no-unused-vars
const name = computed(() => store.getters.username);
// eslint-disable-next-line no-unused-vars
const email = computed(() => store.getters.email);
// eslint-disable-next-line no-unused-vars
const isEEN = computed(() => getConfiguration().theme == "Cameramanager");

const hasPermissions = computed(() => store.getters.invoicePermission || store.getters.paymentPermission || 
                                      store.getters.orderPermission || store.getters.shipmentPermission );

onMounted(async () => {
  const appConfig = getConfiguration();
  const themeName = appConfig.theme ? appConfig.theme : "Cameramanager";
  const theme = new Theme(themeName);
  const themeLogo = theme.logo();
  externalLink.value.name = theme.vmsApplicationName();
  externalLink.value.url = theme.vmsLink();
  const favicon = theme.favicon();
  if (favicon) {
    const faviconDoc = document.getElementById("favicon");
    if (faviconDoc !== null) {
      faviconDoc.href = "/" + favicon;
    }
  }
  if (themeLogo) {
    logo.value = getImgUrl(themeLogo);
  }

  if (loggedIn) {
    await loadData();
  } 
});

watch(loggedIn, async () => {
    if (loggedIn) {
      await loadData();
    }
});

const loadData = async () => {
  try {
    const response = await restapi.getVMSUserData();
    fillAccountInfo(response.data);

    await store.dispatch("getPermissions", response.data.active_account_id);
    if (hasPermissions) {
      const result = await restapi.getBillingAccountMetaData();
      fillAccountMetaData(result.data);
      additionalData.value.value = result.data.account_name;

      if (store.getters.invoicePermission) {
        router.push('Invoices');
      } else if (store.getters.paymentPermission) {
        router.push('Payment')
      }
    }
  } catch (error) {
    store.dispatch("toastMessage", {
      showing: true,
      text: this.$t(error.detail),
      timeout: -1,
      color: "error",
    });
  }
};

const fillAccountInfo = (user) => {
    //change commit to dispatch on new core components version
    store.commit("account", {
      account: user,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
    });
};

const fillAccountMetaData = (user) => {
  store.dispatch("creditTerm", user.credit_terms);
};

// eslint-disable-next-line no-unused-vars
const logout = () => {
  logOut();
};

</script>

<style lang="scss" scoped>
.header-text {
  position: absolute;
  left: 220px;
}

.app-background {
  background: #eeeeee !important;
}
// .container {
//   width: 90% !important;
//   padding-left: 0 !important;
// }
</style>
