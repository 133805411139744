<template>
  <v-container class="pa-0">
    <v-row>
      <v-col 
    
        :class="[$vuetify.breakpoint.xsOnly ? 'pa-0 d-flex justify-center' : '']">

        <v-card class="mt-4 ml-0" 
        :width="$vuetify.breakpoint.xsOnly ? '90%' : ''">
          <v-row>
            <v-col cols="6" offset="3" sm="4" offset-sm="4" lg="3" offset-lg="5">
              <v-menu
                ref="datePicker"
                v-model="dateMenu"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :close-on-content-click="false"
                >

                <template 
                  v-slot:activator="{ on, attrs }"
                  >
                  <v-text-field
                    :value="invoiceDateFormatted"
                    label="Select date"
                    prepend-icon="fa fa-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    class="text-small"
                    @click:clear="invoiceDate = null"
                    
                    >
                  </v-text-field>
                </template>

                <v-date-picker 
                  v-if="dateMenu" 
                  v-model="invoiceDate" 
                  no-title 
                  scrollable
                  @change="datePicker = false">
                </v-date-picker>

              </v-menu>
              <!-- <v-text-field
                v-model="search"
                append-icon="fa-search"
                :label="$t('Search')"
                single-line
                hide-details
              ></v-text-field> -->
            </v-col>
          </v-row>
          <v-data-table 
            :headers="headers"
            hide-default-header
            :items="$store.getters.invoices" 
            :items-per-page="25" 
   
            :loading="loading"
            :server-items-length="$store.getters.totalInvoices"
            :options.sync="options"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100],
              'itemsPerPageText': $t('Items per page')
            }">
              <template v-slot:header="{ props, on}">
                <thead>
                  <tr>
                    <th class="hidden-xs-only table-head" v-for="(header, index) in props.headers" :key="index">
                      <a v-if="header.sortable" @click="on.sort(header.value)" >
                        {{ $t(header.text) }}
                        <v-icon 
                          class="pb-1" 
                          v-if="header.value === props.options.sortBy[0]" 
                          color="grey darken-2" size="12px">
                          {{ props.options.sortDesc[0] ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-up' }}
                        </v-icon>
                      </a>
                      <span v-else>{{ $t(header.text) }}</span>
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:item="{ item, index }" >
                <tr v-if="!$vuetify.breakpoint.xsOnly">
                  <td class="table-cell">{{ item.invoice_id }}</td>
                  <td class="table-cell">{{ item.invoice_date }}</td>
                  <td class="table-cell">{{ $t(item.invoice_type) }}</td>
                  <td class="table-cell">{{ item.balance_due == 0.0 ? $t("Paid") : $t("Unpaid") }}</td>
                  <td class="table-cell">
                    <div class="d-flex flex-row md-justify-end float-right">
                      <v-progress-circular
                        v-if="index == loadingTabIndex"
                        size="25"
                        indeterminate
                        color="primary"
                        class="mr-4 mt-2">
                      </v-progress-circular>

                      <div v-if="loadingTabIndex != index">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                              v-on="on" 
                              v-bind="attrs"
                              icon 
                              class= "mr-3"
                              @click="openInNewTab(item.invoice_id, index)">
                              <v-icon color="grey darken-2" size="18px">fa-external-link-alt</v-icon>
                            </v-btn>
                          </template>
                            <span>{{$t("View")}}</span>
                        </v-tooltip>
                      </div>

                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-menu left offset-y>
                                <template v-slot:activator="{ on }">
                                  <v-btn 
                                    v-on="on" 
                                    icon class="mr-3">
                                    <v-icon color="grey darken-2" size="18px">fa-download</v-icon>
                                  </v-btn>
                                </template>

                                <v-list dense min-width="115">

                                  <v-list-item @click.stop="downloadFile(item.invoice_id, 'pdf')" >
                                    <v-list-item-content>
                                      <v-progress-circular
                                        v-if="downloadingPdf"
                                        size="25"
                                        indeterminate
                                        color="primary">
                                      </v-progress-circular>
                                      <v-list-item-title v-else>PDF</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item >

                                  <v-list-item @click.stop="downloadFile(item.invoice_id, 'detail')" v-if="item.invoice_type == 'Recurring'">
                                    <v-list-item-content>
                                      <v-progress-circular
                                        v-if="downloadingDetail"
                                        size="25"
                                        indeterminate
                                        color="primary"
                                      ></v-progress-circular>
                                      <v-list-item-title v-else>CSV</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>

                                  <v-list-item @click.stop="downloadFile(item.invoice_id, 'summary')" v-if="item.invoice_type == 'Recurring'">
                                    <v-list-item-content>
                                      <v-progress-circular
                                        v-if="downloadingSummary"
                                        size="25"
                                        indeterminate
                                        color="primary">
                                      </v-progress-circular>
                                    <v-list-item-title v-else>CSV Summary</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </template>
                      <span>Download</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>

              <tr v-else class="mobile-tr">
                <td class="pa-0">
                  <ul class ="invoice-header-list">
                    <li class ="invoice-header-item" data-label="order">{{ item.invoice_id }}</li>
                    <li class ="invoice-header-item date" data-label="date">{{ item.invoice_date }}</li>
                    <li class ="invoice-header-item" data-label="type">{{ $t(item.invoice_type) }}</li>
                    <li class ="invoice-header-item status" data-label="status">{{ item.balance_due == 0.0 ? $t("Paid") : $t("Unpaid") }}</li>
                  </ul>
                </td>
              
                <td class="table-cell action-buttons pa-0">
                <div class="d-flex flex-row justify-end float-right">
                  <v-progress-circular
                    v-if="index == loadingTabIndex"
                    size="25"
                    indeterminate
                    color="primary"
                    class="mr-4 mt-2">
                  </v-progress-circular>

                  <div v-if="loadingTabIndex != index">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                          v-on="on" 
                          v-bind="attrs"
                          icon 
                          class="mr-3" 
                          @click="openInNewTab(item.invoice_id, index)">
                          <v-icon color="grey darken-2" size="18px">fa-external-link-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t("Preview")}}</span>
                    </v-tooltip>
                  </div>

                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          <v-menu left offset-y>

                            <template v-slot:activator="{ on }">
                              <v-btn 
                                v-on="on" 
                                icon class="mr-3">
                                <v-icon color="grey darken-2" size="18px">fa-download</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense min-width="115">

                              <v-list-item @click.stop="downloadFile(item.invoice_id, 'pdf')" >
                                <v-list-item-content>
                                  <v-progress-circular
                                    v-if="downloadingPdf"
                                    size="25"
                                    indeterminate
                                    color="primary"
                                  ></v-progress-circular>
                                  <v-list-item-title v-else>PDF</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item >

                              <v-list-item @click.stop="downloadFile(item.invoice_id, 'detail')" v-if="item.invoice_type == 'Recurring'">
                                <v-list-item-content>
                                  <v-progress-circular
                                    v-if="downloadingDetail"
                                    size="25"
                                    indeterminate
                                    color="primary"
                                  ></v-progress-circular>
                                  <v-list-item-title v-else>CSV</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item @click.stop="downloadFile(item.invoice_id, 'summary')" v-if="item.invoice_type == 'Recurring'">
                                <v-list-item-content>
                                  <v-progress-circular
                                    v-if="downloadingSummary"
                                    size="25"
                                    indeterminate
                                    color="primary">
                                  </v-progress-circular>
                                  <v-list-item-title v-else>CSV Summary</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </template>
                    <span>Download</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { reactive, toRefs, watch, computed, } from "vue";
import restapi from "@/service/api";
import { store } from "@/store";
import { t } from "@eencloud/core-components/src/service/locale";
import { SentryProfiling } from "@eencloud/core-components/src/plugins/sentry";

export default {
  name: "Invoices",
  setup() {
    let data = reactive({
      // search: '', // server-side search is not available yet
      invoiceDate: null,
      invoiceDateFormatted: computed(() => { if (data.invoiceDate) { const [year, month, day] = data.invoiceDate.split('-')
          return `${year}/${month}/${day}` }}),
      pdf: null,
      loading: false,
      options: {}, // keep this in the store to keep the tabel state?
      headers: [
        { text: "Invoice #", align: "start", value: "invoice_id", sortable: true},
        { text: "Date", value: "invoice_date", sortable: true },
        { text: "Type", value: "invoice_type", sortable: false},
        { text: "Status", sortable: false},
        { text: '', value: 'expand' }
      ],
      downloadingPdf: false,
      downloadingDetail: false,
      downloadingSummary: false,
      dateMenu: false,
      loadingTabIndex: null,
    });

    const {downloadFile, openInNewTab} = useSingleInvoice(data)

    useInvoicesApi(data);

    return { ...toRefs(data), downloadFile, openInNewTab };
  },
};

function useSingleInvoice(data) {

  async function openInNewTab (invoice_id, index) {
    data.loadingTabIndex = index
    try {
      await SentryProfiling(`Business Portal - Download pdf`, { invoice_id }, `Downloading pdf file`, async ( finishProfiling ) => {
        const response = await restapi.fetchPDF(invoice_id);
        const url = window.URL.createObjectURL(response, {type: "application/pdf"})
        const link = document.createElement('a');
        link.href = url;
        window.open(url, '_blank')
        finishProfiling()
      })
    } catch (error) {
      store.dispatch("toastMessage", {
        showing: true,
        text: t(`There was an error downloading your invoice`),
        timeout: 0,
        color: "error"
      });
    }
    data.loadingTabIndex = null
  }

  async function downloadFile (invoice_id, fileFormat) {
    try {
      await SentryProfiling(`Business Portal - Download ${fileFormat}`, { invoice_id }, `Downloading ${fileFormat} file`, async ( finishProfiling ) => {
        let response
        switch(fileFormat) {
          case 'pdf': 
            data.downloadingPdf = true
            response = await restapi.fetchPDF(invoice_id);
            data.downloadingPdf = false
            break;
          case 'detail': 
            data.downloadingDetail = true
            response = await restapi.getDetailCSV(invoice_id);
            data.downloadingDetail = false
            break;
          case 'summary': 
            data.downloadingSummary = true
            response = await restapi.getSummaryCSV(invoice_id);
            data.downloadingSummary = false
            break;
        }
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        const filename = generateFileName(invoice_id, fileFormat)
        link.download = filename
        document.body.appendChild(link);
        link.click();
        link.target='_blank'
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url)
        finishProfiling()
      })
    } catch (error) {
      store.dispatch("toastMessage", {
        showing: true,
        text: t(`There was an error downloading your invoice`),
        timeout: 0,
        color: "error"
      });
    }
  }

  function generateFileName(invoice_id, fileFormat) {
    const prefix = 'Eagle_Eye_Invoice_'
    let fileType = '.pdf'
    if (fileFormat == 'summary') {
      fileType = '_Customer_Account_Summary.csv'
    } else if (fileFormat == 'detail')
      fileType = '_Line_Item_Details.csv'

    return prefix + invoice_id + fileType
  }

  return {downloadFile, openInNewTab}

}

function useInvoicesApi(data) {

  watch( () => [data.options, data.invoiceDate], () => {
    getInvoices()
  })

  async function getInvoices() {
    try {
      await SentryProfiling(`Business Portal - Load Invoices`, { items: data.options.itemsPerPage }, `Loading invoices`, async ( finishProfiling ) => {
        data.loading = true
        const response = await fetch()
        data.loading = false
        store.dispatch('getInvoices', response.data.results)
        store.dispatch('totalInvoices', response.data.total)
        finishProfiling()
      })
    } catch (error) {
      let errorMessage = `There was an error retrieving your invoices.`
      if (error.response.status == 400) errorMessage = `We couldn't get any invoices for your account.`
      store.dispatch("toastMessage", {
        showing: true,
        text: t(errorMessage),
        timeout: 0,
        color: "error"
      });
    }
  }

  async function fetch() {
    try {
      const { sortBy, sortDesc, page, itemsPerPage } = data.options
      let result_offset = (page -1) * itemsPerPage
      let result_limit = itemsPerPage
      let order = '-invoice_date'

      if (sortBy.length) {
        if (sortDesc[0]) {
          order = '-' + sortBy[0]
        } else {
          order = sortBy[0]
        }
      }
      
      const response = await restapi.getListOfInvoices(result_offset, result_limit, order, data.invoiceDate)
      return response
    } catch (error) {
          store.dispatch("toastMessage", {
                showing: true,
                text: this.$t(error.detail),
                timeout: -1,
                color: "error",
              });    }
  }
}

</script>


<style lang="scss">

  @media (max-width: 599px) {

    .v-data-footer {
      justify-content: space-evenly !important;
      padding: 0px 0px 0px 8px !important;
    }

    .v-application--is-ltr .v-data-footer__pagination {
      margin: 0 6px !important;
    } 

    .v-application--is-ltr .v-data-footer__select .v-select {
      margin: 13px 2px 13px 12px !important;
    }

    .v-application--is-ltr .v-data-footer__select {
      margin-right: 10px;
      padding-left: 2px;
    }
  }
  .mobile-tr {
    display: block;
    position: relative;
    max-width: 100%;
  }
  
  .mobile-tr:nth-child(even) {
    border-left: 6px solid #808080;
  }

  .mobile-tr:nth-child(odd) {
    border-left: 6px solid #24aef5;
  }

</style>

<style lang="scss" scoped>

   .invoice-header-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0px;
    padding: 10px 22px;
    list-style: none;
  }

  .invoice-header-item {
    width: 50%;
    height: 60px;
    margin: 10px 0 0 10px;
    padding: 0px;
    font-weight: bold;
  }

  .invoice-header-item:before {
    display: block;
    content: attr(data-label);
    color: var(--v-anchor-base);
    text-transform: capitalize;
  }

  .date {
    width: 80px; 
  }

  .status {
    width: 50px;
  }

</style>
