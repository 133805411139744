import restapi from "@/service/api"
import { hasInvoice, hasPayment, hasOrder, hasShipment } from "@/service/permissions"

const state = {
  invoicePermission: false,
  paymentPermission: false,
  orderPermission: false,
  shipmentPermission: false
}

const getters = {
  invoicePermission: state => state.invoicePermission,
  paymentPermission: state => state.paymentPermission,
  orderPermission: state => state.orderPermission,
  shipmentPermission: state => state.shipmentPermission
}

const mutations = {
  SET_INVOICE_PERMISSION: (state, permission) => {
    state.invoicePermission = permission
  },
  SET_PAYMENT_PERMISSION: (state, permission) => {
    state.paymentPermission = permission
  },
  SET_ORDER_PERMISSION: (state, permission) => {
    state.orderPermission = permission
  },
  SET_SHIPMENT_PERMISSION: (state, permission) => {
    state.shipmentPermission = permission
  },
}

const actions = {
  async getPermissions({ dispatch }, accountId) {
    const permissions = await restapi.getVMSAccountMetaData(accountId);
    dispatch("setInvoicePermission", hasInvoice(permissions.data.account_permissions));
    dispatch("setPaymentPermission", hasPayment(permissions.data.account_permissions));
    dispatch("setOrderPermission", hasOrder(permissions.data.account_permissions));
    dispatch("setShipmentPermission", hasShipment(permissions.data.account_permissions));
  },
  setInvoicePermission({ commit }, permission) {
    commit("SET_INVOICE_PERMISSION", permission);
  },
  setPaymentPermission({ commit }, permission) {
    commit("SET_PAYMENT_PERMISSION", permission);
  },
  setOrderPermission({ commit }, permission) {
    commit("SET_ORDER_PERMISSION", permission);
  },
  setShipmentPermission({ commit }, permission) {
    commit("SET_SHIPMENT_PERMISSION", permission);
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}