<template>
  <div class="payments-grid">
    <div>
      <v-row v-if="showPaymentCards && paymentOptions">
        <v-col>
          <v-card flat class="mt-4">
            <div class="pa-4">
              <span class="overline payment-headers">{{ $t("Credit Terms") }}</span>
              <v-btn class="mx-2" @click="showPaymentDetails = true" icon>
                <v-icon dark>
                  fas fa-info-circle
                </v-icon>
              </v-btn>
              <div>{{ $store.getters.creditTerm }}</div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" v-for="card in paymentOptions" :key="card.payment_method_id" class="p-0">
          <v-card outlined height="165px">
            <v-row class="px-6">
              <v-col cols="4" class="d-flex justify-center">
                <v-icon size="60">fab{{ selectdeIcon(card.details.brand) }}</v-icon>
              </v-col>
              <v-col cols="8">
                <div>
                  <span class="text-h6 font-weight-regular text-capitalize"> {{ card.details.brand }} </span>
                  <span v-if="card.default_payment_method == true" class="float-right font-weight-medium">
                    {{ $t("Default") }}
                  </span>
                </div>
                <div>
                  <span class="text-h6 font-weight-regular">xxxx-{{ card.details.last4 }}</span>
                  <span v-if="showExpired(card.details)" class="text-h6 font-weight-regular expired-text float-right">
                    {{ $t("Expired") }}
                  </span>
                </div>
                <div>
                  <span class="caption">Exp {{ card.details.exp_month }}/{{ card.details.exp_year }}</span>
                  <span
                    :class="$vuetify.breakpoint.mdAndDown ? 'nick-name-mobile' : 'nick-name'"
                    class="float-right text-truncate d-inline-block"
                    >{{ card.nickname }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end action-border">
              <v-btn @click="deleteCardDetails(card)" color="primary">
                {{ $t("Remove") }}
              </v-btn>
              <v-btn @click="editCardDetails(card)" color="primary">
                {{ $t("Edit") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="p-0" cols="12" sm="6" v-if="paymentOptions">
          <div>
            <v-card outlined height="165px" class="d-flex justify-center align-center">
              <v-btn
                id="add-payment-button"
                :small="$vuetify.breakpoint.xsOnly"
                v-if="paymentOptions && paymentOptions.length < 8"
                color="primary"
                justify="space-around"
                :disabled="loading"
                @click="initializePayment()"
              >
                + {{ $t("Add Payment Method") }}
              </v-btn>
              <p v-if="paymentOptions && paymentOptions.length > 7" class="text-center">
                {{ $t("Please remove a card to add new cards") }}
              </p>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <een-spinner :isloading="loading"></een-spinner>
      <v-dialog v-model="editCard" width="400">
        <v-card>
          <v-form>
            <v-card-title primary-title> </v-card-title>
            <v-card-text>
              <v-text-field
                :label="$t('Expiry Month')"
                flat
                required
                :error-messages="monthErrors"
                @input="$v.expiryMonth.$touch()"
                @blur="$v.expiryMonth.$touch()"
                v-model="expiryMonth"
                dense
              ></v-text-field>
              <v-text-field
                class="mt-4"
                :label="$t('Expiry Year')"
                flat
                :error-messages="yearErrors"
                @input="$v.expiryYear.$touch()"
                @blur="$v.expiryYear.$touch()"
                v-model="expiryYear"
                dense
              ></v-text-field>
              <v-text-field class="mt-4" :label="nickNameLabel" flat v-model="nickname" dense></v-text-field>
              <v-switch v-model="isDefaultMethod" :disabled="defaultPayment" :label="$t('Default')"></v-switch>
            </v-card-text>
            <v-card-actions class="pb-2 justify-end">
              <v-btn
                depressed
                color="primary"
                :disabled="$v.expiryMonth.$invalid || $v.expiryYear.$invalid"
                @click="saveCards()"
              >
                {{ $t("Confirm") }}
              </v-btn>
              <v-btn depressed @click="closeEditCard()">
                {{ $t("Close") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addCardDialog" persistent width="500">
        <v-card>
          <div class="pt-5 px-2">
            <div>
              <v-text-field solo dense v-model="nickname" :label="nickNameLabel"></v-text-field>
            </div>
            <div>
              <v-text-field
                solo
                dense
                :error-messages="nameErrors"
                @input="$v.customerName.$touch()"
                @blur="$v.customerName.$touch()"
                v-model="customerName"
                :label="$t('Cardholder Name')"
                required
              ></v-text-field>
            </div>
            <div>
              <v-autocomplete
                solo
                dense
                :items="countries"
                return-object
                :error-messages="countryErrors"
                @input="$v.countryCode.$touch()"
                @blur="$v.countryCode.$touch()"
                v-model="countryCode"
                item-text="name"
                tem-value="code"
                :label="$t('Country')"
                required
                autocomplete="name"
              ></v-autocomplete>
            </div>
            <div id="card-element">
              <!-- A Stripe card Element will be inserted here. -->
            </div>

            <div id="card-errors" :class="noCardsAdded ? 'mt-2' : ''" role="alert"></div>
          </div>
          <v-card-actions class="mt-6 justify-end">
            <v-btn
              depressed
              color="primary"
              :disabled="!confirmCardButton || $v.customerName.$invalid || $v.countryCode.$invalid"
              id="card-button"
            >
              {{ $t("Confirm") }}
            </v-btn>
            <v-btn depressed @click="closeAddCard()">
              {{ $t("Close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteCardDialog" width="400">
        <v-card>
          <v-card-title>{{ $t("This will delete the card permanenty.") }}</v-card-title>
          <v-card-actions class="pb-2 justify-end">
            <v-btn depressed color="primary" @click="deleteCard()">
              {{ $t("Confirm") }}
            </v-btn>
            <v-btn depressed @click="deleteCardDialog = false">
              {{ $t("Close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showPaymentDetails" max-width="600">
        <v-card class="pa-4" max-width="600">
          <v-row>
            <v-col>
              <v-card class="mt-4">
                <div class="pa-4">
                  <span class="overline payment-headers">{{ $t("Contact Details") }}</span>
                  <div v-for="item in contact" :key="item">
                    {{ item }}
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="mt-4">
                <div class="pa-4">
                  <span class="overline payment-headers">{{ $t("Bank Details") }}</span>
                  <div v-for="item in bank" :key="item">
                    {{ item }}
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-card-actions class="pb-2 justify-end">
            <v-btn depressed @click="showPaymentDetails = false">
              {{ $t("Close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import restapi from "@/service/api";
import { store } from "../store";
import { validationMixin } from "vuelidate";
import { required, maxLength, numeric, minLength, minValue, maxValue } from "vuelidate/lib/validators";
import locale from "@eencloud/core-components/src/service/locale";
import Sentry from "@eencloud/core-components/src/plugins/sentry";
import StripeError from "@eencloud/core-components/src/classes/StripeError";

export default {
  name: "Payment",

  data() {
    return {
      paymentOptions: null,
      loading: false,
      message: "",
      nickname: "",
      countryCode: "",
      expiryMonth: 0,
      customerName: "",
      expiryYear: 0,
      isDefaultMethod: null,
      editCard: false,
      paymentId: null,
      isDisabled: false,
      deleteCardDialog: false,
      addCardDialog: false,
      cardDetails: null,
      language: locale,
      cardElement: null,
      defaultPayment: null,
      showPaymentDetails: false,
      bank: "",
      contact: "",
      showPaymentCards: false,
      confirmCardButton: false,
      countries: [
        { name: "Afghanistan", code: "AF" },
        { name: "Åland Islands", code: "AX" },
        { name: "Albania", code: "AL" },
        { name: "Algeria", code: "DZ" },
        { name: "American Samoa", code: "AS" },
        { name: "AndorrA", code: "AD" },
        { name: "Angola", code: "AO" },
        { name: "Anguilla", code: "AI" },
        { name: "Antarctica", code: "AQ" },
        { name: "Antigua and Barbuda", code: "AG" },
        { name: "Argentina", code: "AR" },
        { name: "Armenia", code: "AM" },
        { name: "Aruba", code: "AW" },
        { name: "Australia", code: "AU" },
        { name: "Austria", code: "AT" },
        { name: "Azerbaijan", code: "AZ" },
        { name: "Bahamas", code: "BS" },
        { name: "Bahrain", code: "BH" },
        { name: "Bangladesh", code: "BD" },
        { name: "Barbados", code: "BB" },
        { name: "Belarus", code: "BY" },
        { name: "Belgium", code: "BE" },
        { name: "Belize", code: "BZ" },
        { name: "Benin", code: "BJ" },
        { name: "Bermuda", code: "BM" },
        { name: "Bhutan", code: "BT" },
        { name: "Bolivia", code: "BO" },
        { name: "Bosnia and Herzegovina", code: "BA" },
        { name: "Botswana", code: "BW" },
        { name: "Bouvet Island", code: "BV" },
        { name: "Brazil", code: "BR" },
        { name: "British Indian Ocean Territory", code: "IO" },
        { name: "Brunei Darussalam", code: "BN" },
        { name: "Bulgaria", code: "BG" },
        { name: "Burkina Faso", code: "BF" },
        { name: "Burundi", code: "BI" },
        { name: "Cambodia", code: "KH" },
        { name: "Cameroon", code: "CM" },
        { name: "Canada", code: "CA" },
        { name: "Cape Verde", code: "CV" },
        { name: "Cayman Islands", code: "KY" },
        { name: "Central African Republic", code: "CF" },
        { name: "Chad", code: "TD" },
        { name: "Chile", code: "CL" },
        { name: "China", code: "CN" },
        { name: "Christmas Island", code: "CX" },
        { name: "Cocos (Keeling) Islands", code: "CC" },
        { name: "Colombia", code: "CO" },
        { name: "Comoros", code: "KM" },
        { name: "Congo", code: "CG" },
        { name: "Congo, The Democratic Republic of the", code: "CD" },
        { name: "Cook Islands", code: "CK" },
        { name: "Costa Rica", code: "CR" },
        { name: "Cote D'Ivoire", code: "CI" },
        { name: "Croatia", code: "HR" },
        { name: "Cuba", code: "CU" },
        { name: "Cyprus", code: "CY" },
        { name: "Czech Republic", code: "CZ" },
        { name: "Denmark", code: "DK" },
        { name: "Djibouti", code: "DJ" },
        { name: "Dominica", code: "DM" },
        { name: "Dominican Republic", code: "DO" },
        { name: "Ecuador", code: "EC" },
        { name: "Egypt", code: "EG" },
        { name: "El Salvador", code: "SV" },
        { name: "Equatorial Guinea", code: "GQ" },
        { name: "Eritrea", code: "ER" },
        { name: "Estonia", code: "EE" },
        { name: "Ethiopia", code: "ET" },
        { name: "Falkland Islands (Malvinas)", code: "FK" },
        { name: "Faroe Islands", code: "FO" },
        { name: "Fiji", code: "FJ" },
        { name: "Finland", code: "FI" },
        { name: "France", code: "FR" },
        { name: "French Guiana", code: "GF" },
        { name: "French Polynesia", code: "PF" },
        { name: "French Southern Territories", code: "TF" },
        { name: "Gabon", code: "GA" },
        { name: "Gambia", code: "GM" },
        { name: "Georgia", code: "GE" },
        { name: "Germany", code: "DE" },
        { name: "Ghana", code: "GH" },
        { name: "Gibraltar", code: "GI" },
        { name: "Greece", code: "GR" },
        { name: "Greenland", code: "GL" },
        { name: "Grenada", code: "GD" },
        { name: "Guadeloupe", code: "GP" },
        { name: "Guam", code: "GU" },
        { name: "Guatemala", code: "GT" },
        { name: "Guernsey", code: "GG" },
        { name: "Guinea", code: "GN" },
        { name: "Guinea-Bissau", code: "GW" },
        { name: "Guyana", code: "GY" },
        { name: "Haiti", code: "HT" },
        { name: "Heard Island and Mcdonald Islands", code: "HM" },
        { name: "Holy See (Vatican City State)", code: "VA" },
        { name: "Honduras", code: "HN" },
        { name: "Hong Kong", code: "HK" },
        { name: "Hungary", code: "HU" },
        { name: "Iceland", code: "IS" },
        { name: "India", code: "IN" },
        { name: "Indonesia", code: "ID" },
        { name: "Iran, Islamic Republic Of", code: "IR" },
        { name: "Iraq", code: "IQ" },
        { name: "Ireland", code: "IE" },
        { name: "Isle of Man", code: "IM" },
        { name: "Israel", code: "IL" },
        { name: "Italy", code: "IT" },
        { name: "Jamaica", code: "JM" },
        { name: "Japan", code: "JP" },
        { name: "Jersey", code: "JE" },
        { name: "Jordan", code: "JO" },
        { name: "Kazakhstan", code: "KZ" },
        { name: "Kenya", code: "KE" },
        { name: "Kiribati", code: "KI" },
        { name: "Korea, Democratic People'S Republic of", code: "KP" },
        { name: "Korea, Republic of", code: "KR" },
        { name: "Kuwait", code: "KW" },
        { name: "Kyrgyzstan", code: "KG" },
        { name: "Lao People'S Democratic Republic", code: "LA" },
        { name: "Latvia", code: "LV" },
        { name: "Lebanon", code: "LB" },
        { name: "Lesotho", code: "LS" },
        { name: "Liberia", code: "LR" },
        { name: "Libyan Arab Jamahiriya", code: "LY" },
        { name: "Liechtenstein", code: "LI" },
        { name: "Lithuania", code: "LT" },
        { name: "Luxembourg", code: "LU" },
        { name: "Macao", code: "MO" },
        { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
        { name: "Madagascar", code: "MG" },
        { name: "Malawi", code: "MW" },
        { name: "Malaysia", code: "MY" },
        { name: "Maldives", code: "MV" },
        { name: "Mali", code: "ML" },
        { name: "Malta", code: "MT" },
        { name: "Marshall Islands", code: "MH" },
        { name: "Martinique", code: "MQ" },
        { name: "Mauritania", code: "MR" },
        { name: "Mauritius", code: "MU" },
        { name: "Mayotte", code: "YT" },
        { name: "Mexico", code: "MX" },
        { name: "Micronesia, Federated States of", code: "FM" },
        { name: "Moldova, Republic of", code: "MD" },
        { name: "Monaco", code: "MC" },
        { name: "Mongolia", code: "MN" },
        { name: "Montserrat", code: "MS" },
        { name: "Morocco", code: "MA" },
        { name: "Mozambique", code: "MZ" },
        { name: "Myanmar", code: "MM" },
        { name: "Namibia", code: "NA" },
        { name: "Nauru", code: "NR" },
        { name: "Nepal", code: "NP" },
        { name: "Netherlands", code: "NL" },
        { name: "Netherlands Antilles", code: "AN" },
        { name: "New Caledonia", code: "NC" },
        { name: "New Zealand", code: "NZ" },
        { name: "Nicaragua", code: "NI" },
        { name: "Niger", code: "NE" },
        { name: "Nigeria", code: "NG" },
        { name: "Niue", code: "NU" },
        { name: "Norfolk Island", code: "NF" },
        { name: "Northern Mariana Islands", code: "MP" },
        { name: "Norway", code: "NO" },
        { name: "Oman", code: "OM" },
        { name: "Pakistan", code: "PK" },
        { name: "Palau", code: "PW" },
        { name: "Palestinian Territory, Occupied", code: "PS" },
        { name: "Panama", code: "PA" },
        { name: "Papua New Guinea", code: "PG" },
        { name: "Paraguay", code: "PY" },
        { name: "Peru", code: "PE" },
        { name: "Philippines", code: "PH" },
        { name: "Pitcairn", code: "PN" },
        { name: "Poland", code: "PL" },
        { name: "Portugal", code: "PT" },
        { name: "Puerto Rico", code: "PR" },
        { name: "Qatar", code: "QA" },
        { name: "Reunion", code: "RE" },
        { name: "Romania", code: "RO" },
        { name: "Russian Federation", code: "RU" },
        { name: "RWANDA", code: "RW" },
        { name: "Saint Helena", code: "SH" },
        { name: "Saint Kitts and Nevis", code: "KN" },
        { name: "Saint Lucia", code: "LC" },
        { name: "Saint Pierre and Miquelon", code: "PM" },
        { name: "Saint Vincent and the Grenadines", code: "VC" },
        { name: "Samoa", code: "WS" },
        { name: "San Marino", code: "SM" },
        { name: "Sao Tome and Principe", code: "ST" },
        { name: "Saudi Arabia", code: "SA" },
        { name: "Senegal", code: "SN" },
        { name: "Serbia and Montenegro", code: "CS" },
        { name: "Seychelles", code: "SC" },
        { name: "Sierra Leone", code: "SL" },
        { name: "Singapore", code: "SG" },
        { name: "Slovakia", code: "SK" },
        { name: "Slovenia", code: "SI" },
        { name: "Solomon Islands", code: "SB" },
        { name: "Somalia", code: "SO" },
        { name: "South Africa", code: "ZA" },
        { name: "South Georgia and the South Sandwich Islands", code: "GS" },
        { name: "Spain", code: "ES" },
        { name: "Sri Lanka", code: "LK" },
        { name: "Sudan", code: "SD" },
        { name: "Suriname", code: "SR" },
        { name: "Svalbard and Jan Mayen", code: "SJ" },
        { name: "Swaziland", code: "SZ" },
        { name: "Sweden", code: "SE" },
        { name: "Switzerland", code: "CH" },
        { name: "Syrian Arab Republic", code: "SY" },
        { name: "Taiwan, Province of China", code: "TW" },
        { name: "Tajikistan", code: "TJ" },
        { name: "Tanzania, United Republic of", code: "TZ" },
        { name: "Thailand", code: "TH" },
        { name: "Timor-Leste", code: "TL" },
        { name: "Togo", code: "TG" },
        { name: "Tokelau", code: "TK" },
        { name: "Tonga", code: "TO" },
        { name: "Trinidad and Tobago", code: "TT" },
        { name: "Tunisia", code: "TN" },
        { name: "Turkey", code: "TR" },
        { name: "Turkmenistan", code: "TM" },
        { name: "Turks and Caicos Islands", code: "TC" },
        { name: "Tuvalu", code: "TV" },
        { name: "Uganda", code: "UG" },
        { name: "Ukraine", code: "UA" },
        { name: "United Arab Emirates", code: "AE" },
        { name: "United Kingdom", code: "GB" },
        { name: "United States of America", code: "US" },
        { name: "United States Minor Outlying Islands", code: "UM" },
        { name: "Uruguay", code: "UY" },
        { name: "Uzbekistan", code: "UZ" },
        { name: "Vanuatu", code: "VU" },
        { name: "Venezuela", code: "VE" },
        { name: "Viet Nam", code: "VN" },
        { name: "Virgin Islands, British", code: "VG" },
        { name: "Virgin Islands, U.S.", code: "VI" },
        { name: "Wallis and Futuna", code: "WF" },
        { name: "Western Sahara", code: "EH" },
        { name: "Yemen", code: "YE" },
        { name: "Zambia", code: "ZM" },
        { name: "Zimbabwe", code: "ZW" },
      ],
    };
  },

  validations: {
    expiryMonth: { required, numeric, maxLength: maxLength(2), minValue: minValue(1), maxValue: maxValue(12) },
    expiryYear: {
      required,
      numeric,
      maxLength: maxLength(4),
      minLength: minLength(4),
      minValue: minValue(2021),
      maxValue: maxValue(2050),
    },
    customerName: {
      required,
    },
    countryCode: {
      required,
    },
  },

  mixins: [validationMixin],

  computed: {
    monthErrors() {
      const errors = [];
      if (!this.$v.expiryMonth.$dirty) return errors;
      !this.$v.expiryMonth.maxLength && errors.push(this.$t("Month should be 2 digits long."));
      !this.$v.expiryMonth.required && errors.push(this.$t("Month is required."));
      !this.$v.expiryMonth.numeric && errors.push(this.$t("Month is a number."));
      !this.$v.expiryMonth.minValue && errors.push(this.$t("Month should be greater than 0."));
      !this.$v.expiryMonth.maxValue && errors.push(this.$t("Month should be fewer than 13."));
      return errors;
    },
    nickNameLabel() {
      return ` ${this.$t("Card Name")} (${this.$t("Optional")})`;
    },
    yearErrors() {
      const errors = [];
      let year = new Date().getFullYear() - 1;
      if (!this.$v.expiryYear.$dirty) return errors;
      !this.$v.expiryYear.maxLength && errors.push(this.$t("Year should be 4 digits long."));
      !this.$v.expiryYear.required && errors.push(this.$t("Year is required."));
      !this.$v.expiryYear.numeric && errors.push(this.$t("Year is a number."));
      !this.$v.expiryYear.minValue && errors.push(this.$t("Year should be greater than YEAR.", { year: year }));
      !this.$v.expiryYear.maxValue && errors.push(this.$t("Year should be fewer than YEAR.", { year: year + 31 }));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.customerName.$dirty) return errors;
      !this.$v.customerName.required && errors.push(this.$t("Name is required."));
      return errors;
    },

    countryErrors() {
      const errors = [];
      if (!this.$v.countryCode.$dirty) return errors;
      !this.$v.countryCode.required && errors.push(this.$t("Country is required."));
      return errors;
    },

    noCardsAdded() {
      return this.paymentOptions ? this.paymentOptions.length === 0 : true;
    },

    oneCardAdded() {
      return this.paymentOptions ? this.paymentOptions.length === 1 : true;
    },
  },

  methods: {
    selectdeIcon(card) {
      let creditcard = card.toLowerCase();
      switch (creditcard) {
        case "visa":
          return " fa-cc-visa";
        case "amex":
          return " fa-cc-amex";
        case "diners":
          return " fa-cc-diners-club";
        case "discover":
          return " fa-cc-discover";
        case "jcb":
          return " fa-cc-jcb";
        case "mastercard":
          return "  fa-cc-mastercard";
        case "unionpay":
          return " fas fa-credit-card";
        case "Unknown":
          return " fas fa-credit-card";
        default:
          return " fas fa-credit-card";
      }
    },
    async initializePayment() {
      Sentry.configureScope((scope) => scope.setTransactionName("StripePayment"));
      this.loading = true;
      this.isDisabled = true;
      try {
        let result = await restapi.initializePaymentMethod();
        this.addCardDialog = true;
        this.loading = false;
        this.$v.$reset();
        this.isDisabled = false;
        this.nickname = "";
        const publishableApiKey = result.data.data.stripe_publishable_key;
        const clientSecret = result.data.data.stripe_client_secret;
        // eslint-disable-next-line
        const stripe = Stripe(publishableApiKey, {
          locale: this.language.getCurrentLanguage(),
        });
        const elements = stripe.elements();
        let style = {
          base: {
            color: "#32325d",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "Black",
            },
          },
        };
        this.cardElement = elements.create("card", { style: style });
        let that = this;
        setTimeout(() => {
          this.cardElement.mount("#card-element");
          this.cardElement.on("change", function (event) {
            var displayError = document.getElementById("card-errors");
            that.confirmCardButton = event.complete;
            if (event.error) {
              displayError.textContent = event.error.message;
            } else {
              displayError.textContent = "";
            }
          });
          const cardButton = document.getElementById("card-button");
          cardButton.addEventListener("click", async () => {
            this.loading = true;
            this.isDisabled = true;
            this.addCardDialog = false;
            // let customerZip = document.querySelector("#customerZip").value;
            const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
              payment_method: {
                card: this.cardElement,
                billing_details: {
                  name: this.customerName,
                  address: {
                    state: this.countryCode.code,
                  },
                },
                metadata: {
                  nickname: this.nickname,
                },
              },
            });

            if (error) {
              this.loading = false;
              this.isDisabled = false;
              this.cardElement.clear("#card-element");
              this.cardElement.destroy("#card-element");
              this.cardElement = "";
              let stripeError = new StripeError(error);
              store.dispatch("toastMessage", {
                showing: true,
                text: error.detail ? this.$t(error.detail) : this.$t('The application has encountered an unknown error, try again later. If the problem persists, contact support.'),
                timeout: -1,
                color: "error",
              });
              Sentry.captureException(stripeError, {
                contexts: {
                  data: stripeError,
                },
              });
              console.log(error);
            } else {
              if (setupIntent.status === "succeeded") {
                this.loading = false;
                this.isDisabled = false;
                this.loadCard();
                this.cardElement.clear("#card-element");
                this.cardElement.destroy("#card-element");
                this.cardElement = "";
                this.nickname = "";
                this.countryCode = "";
                this.customerName = "";
                document.getElementById("card-errors").innerHTML = "";
              }
            }
          });
        }, 500);
      } catch (error) {
        store.dispatch("toastMessage", {
          showing: true,
          text: error.detail ? this.$t(error.detail) : this.$t('The application has encountered an unknown error, try again later. If the problem persists, contact support.'),
          timeout: -1,
          color: "error",
        });
      }
    },

    showExpired(card) {
      let date = new Date();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      if (card.exp_year < year) {
        return true;
      } else if (card.exp_month < month && card.exp_year == year) {
        return true;
      } else {
        return false;
      }
    },

    closeAddCard() {
      this.addCardDialog = false;
      this.cardElement.clear("#card-element");
      this.cardElement.destroy("#card-element");
      this.cardElement = "";
      document.getElementById("card-errors").innerHTML = "";
      this.nickname = "";
      this.countryCode = "";
      this.customerName = "";
    },
    async loadCard() {
      this.loading = true;
      try {
        let result = await this.restapi.getPaymentData();
        this.loading = false;
        this.paymentOptions = result.data.data;
      } catch (error) {
        this.loading = false;
        console.log(error);
        store.dispatch("toastMessage", {
          showing: true,
          text: error.detail ? this.$t(error.detail) : this.$t('The application has encountered an unknown error, try again later. If the problem persists, contact support.'),
          timeout: -1,
          color: "error",
        });
      }
    },

    async saveCards() {
      this.loading = true;
      this.$v.$touch();
      this.editCard = false;
      let details = {
        default_payment_method: this.isDefaultMethod,
        expiration_month: this.expiryMonth,
        expiration_year: this.expiryYear,
        nickname: this.nickname,
      };
      try {
        await this.restapi.editPaymentMethods(this.paymentId, details);
        this.loading = false;
        this.loadCard();
      } catch (error) {
        this.loading = false;
        store.dispatch("toastMessage", {
          showing: true,
          text: error.detail ? this.$t(error.detail) : this.$t('The application has encountered an unknown error, try again later. If the problem persists, contact support.'),
          timeout: -1,
          color: "error",
        });
        console.log(error.response.data);
      }
    },

    closeEditCard() {
      this.$v.$reset();
      this.expiryMonth = "";
      this.editCard = false;
    },

    deleteCardDetails(card) {
      this.cardDetails = card;
      this.deleteCardDialog = true;
    },

    async getPaymentInfo() {
      let that = this;
      try {
        let result = await this.restapi.paymentInformation();

        that.bank = result.data.bank_details;
        that.contact = result.data.contact_details;
        this.showPaymentCards = true;
      } catch (error) {
        store.dispatch("toastMessage", {
          showing: true,
          text: error.detail ? this.$t(error.detail) : this.$t('The application has encountered an unknown error, try again later. If the problem persists, contact support.'),
          timeout: -1,
          color: "error",
        });
      }
    },
    async deleteCard() {
      this.loading = true;
      this.deleteCardDialog = false;
      try {
        await this.restapi.deleteCard(this.cardDetails.payment_method_id);
        this.loading = false;
        this.loadCard();
      } catch (error) {
        this.loading = false;
        console.log(error);
        store.dispatch("toastMessage", {
          showing: true,
          text: error.detail ? this.$t(error.detail) : this.$t('The application has encountered an unknown error, try again later. If the problem persists, contact support.'),
          timeout: -1,
          color: "error",
        });
      }
    },

    async editCardDetails(card) {
      this.editCard = true;
      this.expiryMonth = card.details.exp_month;
      this.expiryYear = card.details.exp_year;
      this.nickname = card.nickname;
      this.isDefaultMethod = card.default_payment_method;
      this.defaultPayment = card.default_payment_method;
      this.paymentId = card.payment_method_id;
    },
  },

  async mounted() {
    this.showPaymentCards = false;
    this.loadCard();
    this.getPaymentInfo();
  },
};
</script>

<style scoped>
#card-errors {
  position: absolute;
  color: #d43536;
  font-size: 12px;
  padding-left: 11px;
}

.action-border {
  border-top: 1px #eeeeee solid;
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.expired-text {
  color: red;
}

@media (min-width: 1920px) {
  .payment-btn-xl-screen {
    width: 1000px !important;
  }
}

#add-payment-button {
  max-width: 95% !important;
}
.nick-name-mobile {
  max-width: 95px;
}
.nick-name {
  max-width: 140px;
}

.payment-headers {
  font-size: 16px !important;
}
.payments-grid {
  width: 100%;
  max-width: 800px;
}
</style>
