import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/components/Dashboard";
import Invoices from "@/components/Invoices";
import Payment from "@/components/Payment"
import ApiList from '@eencloud/eewc-components/src/service/api';
import { plainAuthKey, authKey, getToken, rerouteToOAuthLogin, getJwtFromExchangeToken } from "@eencloud/eewc-components/src/service/auth.js";
import { store } from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    props: true,
    children: [
      { path: "invoices", name: "Invoices", component: Invoices },
      { path: "payment", name: "Payment", component: Payment },
      // { path: "order", name: "Order", component:  },
      // { path: "shipments", name: "Shipments", component:  },
    ],
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.query.exchangetoken) {
    const response = await getJwtFromExchangeToken(to.query.exchangetoken);
    
    if (!response) {
      rerouteToOAuthLogin();
    } else {
      store.commit('LOG_IN');
      next({ path: to.path });
    }

  } else if (to.query.code) {
    await getToken(to.query.code);
    store.commit('LOG_IN');
    const redirectRoute = localStorage.getItem('redirectRoute');
    
    if (redirectRoute) {
      next(JSON.parse(redirectRoute));
      localStorage.removeItem('redirectRoute');
    } else {
      next({ path: '/' });
    }

  } else if (authKey()) {
    const loggedIn = store.getters.loggedIn;

    if (!loggedIn) {
      ApiList.token = authKey();
      plainAuthKey() && (ApiList.plainAuthToken = plainAuthKey());
      store.commit('LOG_IN');
    }
    next();

  } else {
    localStorage.setItem('redirectRoute', JSON.stringify(to));
    rerouteToOAuthLogin();
    return;
  }
});

export default router;
