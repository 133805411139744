import { store } from "@/store";
import restapi from "@/service/api";
import router from "@/service/router"

export async function login(user) {
  try {
    const authenticateRequest = await restapi.authenticate(user);
    if (authenticateRequest.two_factor_authentication_code) return authenticateRequest
    await restapi.authorize(authenticateRequest.token);
    const response = await restapi.getVMSUserData();
    await store.dispatch("getPermissions", response.data.active_account_id);
    store.dispatch("login")
    router.push({ path: '/' })
  } catch (error) {
    logout()
    console.log(error);
  }
}

export async function loginWithTFA(token, code) {
  try {
    await restapi.authorize(token, code)
    const response = await restapi.getVMSUserData();
    await store.dispatch("getPermissions", response.data.active_account_id);
    store.dispatch("login")
    router.push({ path: '/' })
  } catch (error) {
    console.log(error);
  }
}

export async function logout() {
  try {
    await restapi.logOut();
    store.dispatch("logout");
    router.push({ path: '/logout' })
  } catch (error) {
    console.log(error);
  }
}

export default {
  login,
  logout,
  loginWithTFA
};
