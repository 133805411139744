const state = {
  bank: '',
  contact: '',
  creditTerm: '',
};

const getters = {
  bank: (state) => {
    return state.bank;
  },
  contact: (state) => {
    return state.contact;
  },
  creditTerm: (state) => {
    return state.creditTerm;
  },
};

const mutations = {
  CALL_BANK: (state, bank) => {
    state.bank = bank;
  },

  CALL_CONTACT: (state, contact) => {
    state.contact = contact;
  },

  CALL_CREDITTERM: (state, creditTerm) => {
    state.creditTerm = creditTerm;
  },
};

const actions = {
  bank({ commit }, message) {
    commit("CALL_BANK", message);
  },
  contact({ commit }, message) {
    commit("CALL_CONTACT", message);
  },
  creditTerm({ commit }, message) {
    commit("CALL_CREDITTERM", message);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
