<template>
  <v-container class="full-height ma-0 pa-0" fluid>
    <v-row class="full-height">
      <v-col class="pa-0 full-height" cols="auto">
        <een-side-menu :showName="false" :menus="filteredMenus" :currentEndpoint="$route.name" @itemSelected="navigate" menuIndex="0">
        </een-side-menu>
      </v-col>
      <v-col :class="$vuetify.breakpoint.smAndDown ? 'py-0 px-2' : 'py-0 px-9'">
        <div class="d-flex justify-center">
          <router-view class="settings-content" toolbar-color="transparent">  
          </router-view>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { store } from "../store";

export default {
  name: "DashBoard",

  data: () => ({
    menus: [
      {
        name: "Invoices",
        endpoint: "Invoices",
        icon: "fas fa-receipt",
      },
      {
        name: "Payment Methods",
        endpoint: "Payment",
        icon: "fas fa-credit-card",
      },
    ],
  }),
  methods: {
    navigate(menu) {
      if (this.$router.name !== menu.endpoint)
        this.$router.push({ name: menu.endpoint });
    },
  },

  computed: {
    filteredMenus() {
      return this.menus.filter((menu) => {
        switch (menu.endpoint) {
          case "invoices":
            return store.getters.invoicePermission
          case "payment":
            return store.getters.paymentPermission
          case "order":
            return store.getters.orderPermission
          case "shipments":
            return store.getters.shipmentPermission
          default:
            return true
        }
      })
    },
  },
};
</script>

<style scoped>

  .full-height {
    height: 100% !important;
  }

  .settings-content{
    margin-bottom: 70px;
  }

</style>