import Vue from "vue";
import Vuex from "vuex";

import authStatus from "./modules/authStatus";
import invoices from "./modules/invoices"
import accountDetails from "@eencloud/core-components/src/storeModules/accountDetails";
import coreData from "@eencloud/core-components/src/storeModules/coreData";
import permissions from "./modules/permissions";
import account from "./modules/account"

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    authStatus,
    coreData,
    accountDetails,
    invoices,
    permissions,
    account
  },
});
